import React from 'react'
import { graphql } from 'gatsby'
import { CardSecondary } from '../components/Card'
import Fade from 'react-reveal/Fade'
import App from '../components/App'
import Hero from '../components/Hero'
import Main from '../components/Main'
import Grid from '../components/Grid'
import { generateSlug } from '../helpers/slug'
import Categories from '../components/Categories'
import Wrapper from '../components/Wrapper'
import { HrefLangMeta } from '../components/Locale'
import lang from '../../static/lang.json'
import SEO from '../components/SEO'
import CanonicalLink from '../components/CanonicalLink'

const Category = ({ data, location, ...rest }) => (
  <App>
    <HrefLangMeta {...data.translate} sub="products" />
    <SEO {...data.page} />
    <CanonicalLink canonical={data.page.canonical} />
    <Wrapper>
      <Categories list locale={data.page.node_locale} />

      {data.page && (
        <Hero
          title={data.page.title}
          breadcrumbs={[
            {
              label: lang.products[data.page.node_locale.toLowerCase()],
              href: lang.products[data.page.node_locale.toLowerCase()],
            },
          ]}
        />
      )}

      <Main marginless>
        <Fade cascade>
          <Grid>
            {data.products &&
              data.products.edges.map(({ node }, i) => {
                return (
                  <CardSecondary
                    {...node}
                    linkText={
                      lang.productsView[data.page.node_locale.toLowerCase()]
                    }
                    key={node.contentful_id}
                    name={node.title}
                    url={generateSlug(`/${location.pathname}/${node.title}`)}
                  />
                )
              })}
          </Grid>
        </Fade>
      </Main>
    </Wrapper>
  </App>
)

export default Category
export const productsQuery = graphql`
  query getCategoryByID($contentful_id: String, $node_locale: String) {
    translate: contentfulCategory(
      node_locale: { ne: $node_locale }
      contentful_id: { eq: $contentful_id }
    ) {
      title
      node_locale
    }

    page: contentfulCategory(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      canonical
      node_locale
      image {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }

    products: allContentfulProduct(
      filter: {
        category: {
          contentful_id: { eq: $contentful_id }
          node_locale: { eq: $node_locale }
        }
      }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          title
          node_locale
          contentful_id
          image {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          category {
            title
          }
        }
      }
    }
  }
`
